.indexpage {

	.home-welcome-block {
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,dddddd+100&0+51,1+100 */
		background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgba(127, 155, 188, 0) 51%, rgba(221, 221, 221, 1) 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgba(127, 155, 188, 0) 51%, rgba(221, 221, 221, 1) 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(127, 155, 188, 0) 51%, rgba(221, 221, 221, 1) 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e5799', endColorstr='#dddddd', GradientType=0);
		/* IE6-9 */


	}

	.week-plan-bl {
		background-color: var(--lightblue);
		margin-top: 60px;
		padding: 0 15px;

		img {
			width: 100%;
		}

		.week-bl-content {
			padding: 45px;
			font-weight: 300;

			@media (max-width:992px) {
				padding: 24px;
			}

			p {
				line-height: 1.8;
			}

			ul {

				li {
					margin-bottom: 10px;

					span {
						min-width: 250px;
						display: inline-block;

						@media (max-width:550px) {
							display: block;
						}
					}
				}
			}

			.btn {
				letter-spacing: 1.5px;
				font-size: 13px;
			}
		}
	}

	.items-slide {
		text-align: center;

		.item {
			max-width: 390px;
			display: inline-block;
			background-size: cover;
			background-position: 50%;
			background-repeat: no-repeat;
			width: 100%;
			text-align: center;
			margin-top: 20px;

			.offer-img-bl {
				position: relative;

				img {
					object-fit: cover;
					object-position: center;
					height: 300px;
					width: 100%;
				}
			}

			p {
				min-height: 59px;
			}


			.item-offer-bl {
				background: #fff;
				position: relative;
				top: -50px;
				padding: 20px 10px 0;
				text-align: center;

				@media (min-width:551px) {
					min-height: 170px;
					max-width: 280px;
					width: 90%;
				}


				display: inline-block;
				text-align: center;
				color: var(--black);

				.link {
					letter-spacing: 1px;

					&:hover {
						color: var(--secondary);
					}
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 40%;
			left: 0;
			width: 100%;



			button {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 1px solid #b1b1b1;
				border-radius: 50%;
				box-shadow: 0 0 8px rgba(0, 0, 0, .21);
				background-color: var(--white);
				position: absolute;
				left: -60px;

				@media (max-width:1300px) {
					left: 0;
				}

				span {
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					-webkit-font-smoothing: antialiased;
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--primary);
						font-family: "Font Awesome 5 Free";
						position: relative;
						left: 2px;
					}
				}

				&.owl-next {
					position: absolute;
					right: -60px;
					left: initial;

					@media (max-width:1300px) {
						right: 0;
					}

					span {

						&:before {
							content: "\f061";
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f060";

						}
					}
				}
			}
		}

	}

	.parallax-banner {
		background-image: url(RESOURCE/img/Bild 4.jpeg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		padding: 60px 0;
		position: relative;

		.container {
			position: relative;
			z-index: 1;
		}

		&:after {
			background-color: rgba(255, 255, 255, .5);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
		}

		@media (max-width:550px) {
			padding: 40px 0;
		}

		.feature-block {
			background: #fff;
			padding: 50px;
			display: inline-block;
			max-width: 610px;

			@media (max-width:992px) {
				padding: 25px;
			}
		}
	}

	.why-book-us {
		padding: 0 15px;

		.row {
			display: flex;
			flex-flow: wrap;
		}

		.why-ct-bl {
			background: #ffffff;
			text-align: center;
			padding: 30px 16px;
			border-right: 1px solid lightgrey;

			&:last-child {
				border-right: none !important;
			}

			@media (max-width:1200px) {
				border-bottom: 1px solid lightgrey;


				&:nth-child(3n) {
					border-right: none;
				}
			}

			@media (max-width:767px) {

				&:nth-child(2n),
				&:nth-child(4n) {
					border-right: none;
				}

				&:nth-child(3n) {
					border-right: 1px solid lightgrey;
				}
			}

			.icon-img {
				display: inline-block;
				margin-bottom: 13px;
			}

			h5 {
				font-size: 14px;
				margin: 0 0 6px;
			}

			p {
				font-size: 12px;
			}
		}
	}

	.latest-bl-area {

		.blog-block {
			position: relative;


		}

		.bl-img {
			position: relative;
			width: 100%;

			@media (min-width:551px) {
				width: 50%;
			}

			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center;
				object-position: center;
				height: 285px;
				width: 100%;
			}
		}

		.bl-small {
			margin-bottom: 15px;

			@media (min-width:551px) {
				display: flex;
				margin-bottom: 50px;
			}

			@media (max-width:767px) {}

			&.bl-reverse {
				@media (min-width:551px) {
					flex-direction: row-reverse;
				}
			}
		}

		.lt-bl-cont {
			background: var(--light-grey);
			padding: 20px;
			text-align: center;
			display: inline-block;
			width: 100%;
			color: var(--black);

			@media (min-width:551px) {
				max-width: 50%;
				padding: 40px 35px;
			}

			.bl-title {
				letter-spacing: 1px;
				font-size: 19px;
				margin-bottom: 30px;
			}

			.date {
				font-size: 13px;
				margin-bottom: 20px;
				color: var(--darkgrey);
				font-weight: normal;

			}
		}
	}

	.home-unit-view {
		overflow: hidden;

	}


	.testimonials-bl {
		border-top: 1px solid #efefef;

		.client-testimonial {
			padding: 0 55px;

			.owl-nav {
				button {
					width: 24px;
					height: 24px;
					box-shadow: inherit;
					-webkit-box-shadow: inherit;


					&.owl-prev {
						span {
							&:before {
								content: "\f104";
								color: var(--grey2);
							}
						}
					}

					&.owl-next {
						span {
							&:before {
								content: "\f105";
								color: var(--grey2);
							}
						}
					}
				}
			}
		}



		.review-side-bl {
			text-align: center;

			@media (max-width:767px) {
				margin-top: 30px;
			}

			h3 {
				font-size: 26px;
				margin-bottom: 0px;
			}

			.ratings {
				margin-top: 18px;
				margin-bottom: 12px;
				display: block;
				text-align: center;

				i {
					font-size: 18px;
					margin: 1px;
				}
			}

			p {
				font-size: 13px;
				margin-bottom: 18px;
			}
		}

		.itm-testi-bl {
			position: relative;

			h4 {
				font-size: 15px;
				margin-top: 14px;
				margin-bottom: 14px;
			}

			dt {
				color: rgba(0, 0, 0, .5);
				font-size: 12px;
				position: absolute;
				right: 0;
				top: 0;
				font-weight: normal;
			}


			p {
				margin-bottom: 24px;
			}

			.client-name {
				font-size: 12px;
			}
		}

		.ratings {
			display: flex;

			i {
				margin-right: 2px;
				padding: 4px;
				background: var(--green);
				color: var(--white);
				font-size: 14px;
			}
		}
	}



	.slide-single-unit {
		margin-bottom: 20px;

		.image-block {
			position: relative;

			img {
				object-fit: cover;
				object-position: center;
				height: 260px;
				width: 100%;
			}

			.fav-icon {
				position: absolute;
				top: 15px;
				right: 15px;
				color: var(--primary);
				font-size: 20px;
			}
		}

		.unit-cont-home {
			border: 1px solid #e8e8e8;
			border-top: none;
			padding: 20px;

			.unit-address {
				color: var(--grey2);
				position: relative;
				padding-left: 20px;

				i {
					position: absolute;
					left: 0;
					top: 3px;

				}
			}

			.ratings {
				margin-bottom: 0;
				min-height: inherit;

				i.fa {
					color: var(--primary);
					margin-bottom: 15px;
				}
			}

			.unit-detail {
				margin-bottom: 25px;

				li {
					margin-right: 15px;
					display: inline-block;
					color: var(--grey2);

					i {
						margin-right: 6px;

					}
				}
			}

			.rent-rate {
				position: relative;
				padding-right: 125px;
				min-height: 40px;

				h3 {
					display: inline-block;
					margin-bottom: 0;
				}

				.btn {
					position: absolute;
					right: 0;
					top: 5px;
				}
			}
		}

		.unit-title {
			color: var(--black3);

			&:hover {
				color: var(--primary);
			}
		}


	}

	.latest-deals {
		.owl-nav {
			margin-top: 35px;

			.owl-prev {
				margin-left: -15px;
			}

			.owl-next {
				margin-right: -15px;
			}

			span:before {
				color: var(--black);
			}
		}

	}

}

.row {
	.ct-col-6 {
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
		float: left;
		width: 100%;

		@media (min-width:993px) {
			width: 50%;
		}

		&:nth-child(2),
		&:nth-child(3) {
			.blog-block {
				@media (min-width: 993px) {
					flex-direction: row-reverse;
				}

				&:after {
					@media (min-width: 993px) {
						border-left: 30px solid var(--light-grey);
						border-right: none !important;
					}
				}
			}
		}

		&:nth-child(2),
		&:nth-child(4) {
			.blog-block {
				@media (max-width: 992px) {
					flex-direction: row-reverse;
				}

				&:after {
					@media (max-width: 992px) {
						border-left: 30px solid var(--light-grey);
						border-right: none !important;
					}
				}
			}
		}


		.blog-block {
			@media (min-width: 551px) {
				display: flex;
			}

			@media (max-width: 992px) {
				margin-bottom: 30px;

			}

			&.link-line {
				&:after {
					position: absolute;
					width: 0;
					height: 0;
					border-top: 30px solid transparent;
					border-bottom: 30px solid transparent;
					border-right: 30px solid var(--light-grey);
					content: "";
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);

					@media (max-width: 992px) {
						display: none;
					}
				}
			}

			.bl-img {
				img {
					height: 285px;
				}
			}
		}
	}

	.bg-teaser {
		background-image: url(RESOURCE/img/teaser-bg.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}
}

.bg-teaser {
	background-image: url(RESOURCE/img/teaser-bg.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}