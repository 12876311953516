.static {
	display: block;
}

.region-view {
	@media (max-width:992px) {
		padding-top: 40px;
	}
}


.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}

.inner-banner {
	background-image: url(RESOURCE/img/StrandStrandkorbStatic.jpeg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 320px;
	position: relative;
	margin-top: 70px;

	@media (max-width:992px) {
		background-image: url(RESOURCE/img/head-mob2.jpg);
	}

	@media (max-width:992px) {
		height: 320px;
		margin-top: 60px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		.team-dt {
			border: 1px solid #e8e8e8;
			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}

@media (min-width:768px) {
	.d-sm-flex {
		display: flex;
	}

	.flex-sm-wrap {
		flex-wrap: wrap;
	}
}

.block-1 {
	background-color: var(--light-grey);
	padding: 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h3 {
		color: var(--secondary);
		font-size: 18px;
		margin-bottom: 12px;
	}

	img {
		margin-bottom: 10px;
	}
}