:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--primary: #F6AB0A;
	--secondary: #A20000;
	--lightblue: #e9eff6;
	--black: #000000;
	--white: #fff;
	--bodyfont: 'Poppins', sans-serif;
	--grey: #4e4e4e;
	--light-grey: #f5f5f5;
	--darkgrey: #666666;
	--black2: #333333;
	--green: #01bd86;
	--black3: #14141f;
	--grey2: #b7b7b7;
	--red: #ff0000;
	--grey3: #8a8a8a;
	--black4: #484848;
	--darkblue: #356F99;
	--black5: #4d4d4d;

	--not_available: #b40000;
	--disabled: #79BC80;
	--available: #3c9d46;
	--selected: #1b7eb7;
	--selection_range: #bad1e9;
	--selected_border: #fff;
	--text_color: #fff
}